import React from 'react';

const BrandList = [
    {
        image: 'https://res.cloudinary.com/duooqqgsu/image/upload/v1637680419/scinaptech/LOGO_CENTRO_HIST_g49xlq.png'
    },
    {
        image: 'https://res.cloudinary.com/duooqqgsu/image/upload/v1637680419/scinaptech/LOGO_INM_xlxw65.png'
    },
    {
        image: 'https://res.cloudinary.com/duooqqgsu/image/upload/v1637680418/scinaptech/LOGO_MEXIQUENSE_ndzmz5.png'
    },
    {
        image: 'https://res.cloudinary.com/duooqqgsu/image/upload/v1637680418/scinaptech/LOGO_SENADO_jvr3le.png'
    }
]

const BrandFour = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandFour;
