import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row pb-5">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address h-100">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Teléfono</h4>
                                        <p><a href="tel:+52 1 55 6413 0164">+52 1 55 6413 0164</a></p>
                                        {/* <p><a href="tel:+222 222 222 333">+222 222 222 333</a></p> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 ">
                                <div className="rn-address h-100">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Correo electrónico</h4>
                                        <p><a href="mailto:admin@scinaptech.com.mx ">admin@scinaptech.com.mx </a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address h-100">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Locación</h4>
                                        <p>Oxford 36, Juárez, Cuauhtémoc, 06600 Juárez, CDMX México</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                {/* <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div> */}
            </div>
        </>
    )
}
export default ContactOne;