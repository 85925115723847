import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        
        image: 'https://res.cloudinary.com/duooqqgsu/image/upload/v1637686264/scinaptech/SERVICIOS_02_bmerky.png',
        title: 'Software Ciberseguridad',
        description: 'Plataformas digitales de pago así como servicio/diseñado a la medida desde páginas web, arquitectura e infraestructura de nube y bases de datos, aplicaciones móviles, e incluso software especializado de antivirus, ERP´s, CRM´S, Big Data y Data Science Dashboards, Machine Learning, Scrapping, Crawling, y tecnologías similares.'
    },
    {
        image: 'https://res.cloudinary.com/duooqqgsu/image/upload/v1637686264/scinaptech/SERVICIOS_01_lyrxsx.png',
        title: 'Publicidad / Marketing digital / Ecommerce',
        description: 'Campañas Digitales, Redes Sociales, <br> Puntos de Influencia, Social Listening. <br> Compra y venta de productos y/o servicios a través de internet ECOMMERCE, tales como redes sociales y otras páginas web.'
    },
    {
        image: 'https://res.cloudinary.com/duooqqgsu/image/upload/v1637686264/scinaptech/SERVICIOS_04_jusful.png',
        title: 'Promoción Turística',
        description: 'La Promoción Turística contempla la difusión de un destino turístico. Es decir, llevar a cabo acciones con el objetivo de dar a conocer un lugar. Y cómo queremos que el turista perciba nuestro destino.'
    },
    {
        image: 'https://res.cloudinary.com/duooqqgsu/image/upload/v1637686264/scinaptech/SERVICIOS_03_itdplk.png',
        title: 'Consultoría Digital Y Tecnológica',
        description: 'Creación de modelos de negocio y <br> operación digital. Software de mando y Seguridad'
    },
    {
        image: 'https://res.cloudinary.com/duooqqgsu/image/upload/v1637686264/scinaptech/SERVICIOS_05_ric6qq.png',
        title: 'Seguros',
        description: 'Agente de todo tipo de seguros con cualquier aseguradora'
    }
]
const ServiceTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5" key={i}>
                    <ScrollAnimation 
                    className="h-100"
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={"h-100 card_box_info " + `card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    
                                        <img src={`${val.image}`} alt="card Images" />
                                    
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                    {/* <Link className="btn-default btn-small btn-border" to="#service">Read More</Link> */}
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;