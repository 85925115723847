import React from 'react'
import Layout from "../common/Layout";


const PoliticaAmbiental = () => {
    return (
        <Layout>
            <div className="ptb--200 ptb_sm--60 ptb_md--80">
                <div className="container">
                    <div className="row align-item-center">
                        <div className="col-lg-12">
                            <h2>Politica Ambiental</h2>
                            
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>SCINAPTECH,&nbsp;</span><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>se compromete a realizar prácticas operativas seguras en materia de medio ambiente, a través de la integración de principios, programas y prácticas relacionadas con el mismo en cada una de las operaciones que realiza, así como dentro de cada área de la empresa.</span></p>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>La presente política se va a dividir en 4 áreas enfocadas a su uso en la empresa y que son: Energía, Educación Ambiental, Reducción en Uso de Insumos y Uso Eficiente y Responsable de los Recursos Hídricos</span></p>
        <p><br /></p>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>PROPÓSITO&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>SCINAPTECH, a través de esta política busca ratificar el compromiso con el medio ambiente, mediante el establecimiento de principios que orientan a la Compañía para apoyar y mejorar su desempeño ambiental. Esta política servirá como un referente para todos los empleados, socios, proveedores, clientes y colaboradores en general, promoviendo al compromiso de cumplir con los siguientes ámbitos de actuación:</span></p>
        <ul style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Respeto por el entorno económico, social y medioambiental.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Prevenir, reducir y eliminar en la manera de la posibilidad la afección ambiental que pueda derivarse de las actividades que se desarrollen.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Identificar y controlar los aspectos ambientales asociados a las actividades administrativas, de gestión y servicios.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Cumplir con las disposiciones legales que existan en materia ambiental.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Promover el reciclaje.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Establecer anualmente los objetivos y metas ambientales y evaluar el grado de avance conseguido respecto a años anteriores.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Dar publicidad y difundir la política ambiental para el conocimiento y respeto de la misma ante la sociedad y con los que laboran en la empresa.</span></p>
          </li>
        </ul>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>ALCANCE&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Esta Política es aplicable a todos los colaboradores de SCINAPTECH, así como a todas las partes relacionadas y grupos de interés, entendidos estos como: clientes, proveedores, accionistas, inversionistas, aliados, empleados, contratistas, subcontratistas; y en general a todos aquellos con quienes de manera directa o indirectamente se establezca alguna relación comercial, contractual, laboral o de cooperación.&nbsp;</span></p>
        <p><br /></p>
        <p><br /></p>
        <p><br /></p>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>ENERGÍA</span></p>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>SCINAPTECH se compromete a minimizar el impacto nocivo que proviene del consumo de sus actividades. Por ello se asignará a las áreas correspondiente, el establecer un programa de consumo sustentable, con el fin de reducir el consumo.</span></p>
        <ul style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Se realizarán anualmente campañas de formación y sensibilización enfocas en el ahorro de consumo de energía del personal de la empresa, se enviarán comunicados a los correos institucionales del personal, donde se comunicarán recomendaciones y datos sobre la importancia del ahorro de energía.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Se utilizarán focos, lámparas y equipos electrónicos de bajo consumo eléctrico. &nbsp;</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Se moderará la intensidad de la luz en las zonas que no sea tan necesaria y se revisarán continuamente los niveles de iluminación, así como se utilizarán los aires acondicionados sólo cuando sean necesarios.&nbsp;</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>El área de soporte técnico realizará el cambio de equipos que después de una valoración y revisión, se determine que no hacen uso eficiente de la energía.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>El personal de la empresa desconectará los sistemas eléctricos que no se estén usando al momento de terminar sus actividades.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Se organizarán los puestos de trabajo para poder aprovechar al máximo la luz natural.</span></p>
          </li>
        </ul>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>EDUCACIÓN AMBIENTAL</span></p>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>SCINAPTECH elaborará un plan de comunicación y educación ambiental en los ámbitos que apliquen para la empresa, dirigido a los que colaboren en la empresa, buscando siempre la transmisión y difusión de las buenas prácticas ambientales de modo constante, culminando en la mejora continua en la aplicación de los puntos mencionados anteriormente.</span></p>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>REDUCCIÓN EN USO DE INSUMOS</span></p>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>El uso de insumos dentro de la empresa buscará reducirse para disminuir la degradación de la naturaleza. Se seguirán los siguientes puntos:</span></p>
        <ul style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Uso adecuado de equipos, con el fin de evitar el consumo de energía excesivo o daños en los equipos, que potencialmente terminarán generando residuos, siguiendo lo anterior se prolongará la vida útil de los mismos.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Disminuir el uso de material publicitario en papel, promoviendo la comunicación por medios digitales, ya sean por correo electrónico o medios alternativos, buscando que se reduzca el consumo de papel y tintas y así se generen menos residuos en general.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Se buscará reducir el consumo de papel, uno de los principales insumos de oficina, buscando concientizar en la revisión de los trabajos a imprimir y su importancia, optando por el almacenamiento digital y la reutilización de hojas.</span></p>
          </li>
        </ul>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>USO EFICIENTE Y RESPONSABLE DE LOS RECURSOS HÍDRICOS</span></p>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>SCINAPTECH, es consciente de la importancia del agua como recurso natural, por lo que buscará la optimización de su consumo para disminuir el impacto ambiental negativo.</span></p>
        <ul style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Se realizarán campañas de sensibilización, información y capacitación tendientes a la disminución del consumo de agua entre el personal del municipio.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Utilizar aparatos sanitarios de bajo consumo de agua.</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre'}}>
            <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Programar controles de calidad y cantidad de las fuentes de agua.</span></p>
          </li>
        </ul>
        <p dir="ltr" style={{lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '10pt'}}><span style={{fontSize: '9pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Todos los que integran SCINAPTECH asumen estos principios y es responsabilidad de cada uno llevarlos a la práctica, mediante el impulso a la mejora ambiental y la asignación de los recursos necesarios para asegurar la exitosa implantación de esta política ambiental.</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PoliticaAmbiental;