import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabOne = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-5">
                                {/* https://res.cloudinary.com/duooqqgsu/image/upload/v1637692814/scinaptech/FILOSOFIA_1000x720_funhxu.png */}
                                    <img className="radius-small" src='https://res.cloudinary.com/duooqqgsu/image/upload/v1637692814/scinaptech/FILOSOFIA_1000x720_funhxu.png' alt="Corporate React Template" />
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Misión</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Visión</button>
                                                    </div>   
                                                </Tab>
                                                
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                        <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Brindar la mayor innovación en tecnología digital para el sector gubernamental.</p>

                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Cumplir con las expectativas de cada proyecto y ser siempre un proveedor de confianza.</p>

                                                    </div>
                                                </div>
                                            </TabPanel>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabOne
