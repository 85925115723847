import React from 'react'
import Layout from "../common/Layout";


const PoliticaAnticorrupcion = () => {
    return (
        <Layout>
            <div className="ptb--200 ptb_sm--60 ptb_md--80">
                <div className="container">
                    <div className="row align-item-center">
                        <div className="col-lg-12">
                            <h2>Politica Anticorrupcion</h2>
                            <ol style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'upper-roman', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>PROPÓSITO</span></p>
          </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>SCINAPTECH, sus subsidiarias y filiales, a través de la presente política se compromete en atender el cumplimiento de los requerimientos legales anticorrupción, mediante la adopción de una filosofía basada en realizar negocios &nbsp; con altos estándares de honestidad, integridad, honradez y responsabilidad, como principios fundamentales que evidencian el compromiso de actuar apegados a la integridad empresarial y trasladándola a todos los miembros de la compañía.&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Mediante este actuar SCINAPTECH asume un compromiso público con la gestión y el desempeño responsable mediante un actuar ético y transparente ante sus grupos de interés y la conducción de sus negocios de una manera socialmente responsable, actuando con una filosofía de Cero Tolerancia a actos que contraríen sus principios organizacionales.</span></p>
        <p><br /></p>
        <ol start={2} style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'upper-roman', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>ALCANCE&nbsp;</span></p>
          </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Esta Política es aplicable a todos los colaboradores de SCINAPTECH, así como a todas las partes relacionadas y grupos de interés, entendidos estos como: clientes, proveedores, accionistas, inversionistas, aliados, empleados, contratistas, subcontratistas; y en general a todos aquellos con quienes de manera directa o indirectamente se establezca alguna relación comercial, contractual, laboral o de cooperación.&nbsp;</span></p>
        <p><br /></p>
        <ol start={3} style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'upper-roman', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>DEFINICIONES</span></p>
          </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Corrupción</span><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>: Es todo hecho, tentativa u omisión deliberada para obtener un beneficio para sí o para terceros en detrimento de los principios organizacionales, independiente de los efectos financieros sobre las empresas.&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Soborno:</span><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;Consiste en ofrecer, prometer, dar, aceptar o solicitar una contraprestación, económica o de con cualquier otro tipo de cosa de valor, con el propósito de dirigir la conducta de alguien para obtener ventaja de índole comercial, contractual, reglamentaria o personal, para motivar a alguien a abstenerse de realizar un acto relacionado a sus funciones o para que abusen de su influencia. &nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>El soborno y la corrupción son delitos penales que afectan tanto a quienes participan en forma activa como pasiva en la comisión de estos hechos, así como a las organizaciones a quienes representan.&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Cosas de valor:</span><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; Es un concepto amplio y puede incluir dinero en efectivo, equivalentes de efectivo (por ejemplo, tarjetas de regalo), comidas, entretenimiento, viajes, obsequios, empleo, contratos, servicios en especie, o cualquier otro tipo de bienes o servicios con valor económico tangible, contribuciones a fines benéficos o a otras organizaciones sin ánimo de lucro y patrocinios promocionales.</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Servidor/Funcionario público</span><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>: Cualquier empleado de un órgano de gobierno, electo o designado como integrante de los Poderes Ejecutivo, Legislativo y Judicial, órganos constitucionales autónomos, empresas en las que tenga participación el Estado o agencias de investigación, perteneciente a cualquier nivel de gobierno (federal, nacional, estatal, provincial o municipal) o cualquier partido político, funcionario de un partido político o candidatos para cualquier puesto de elección popular.</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Socio Comercial:</span><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;Terceros actuando en nombre o por cuenta de la Compañía o de cualquier entidad propiedad de, o controlada por la Compañía, que puedan interactuar con agentes externos, especialmente en el caso en que estos sean Servidores/Funcionarios Públicos; o cualquier alianza en la que la Compañía tenga intereses económicos, en los que intervengan, entre otros, a modo de ejemplo: agentes, brokers, intermediarios, asesores, consultores, representantes, socios de jointventures, co-inversores, franquiciados, proveedores autorizados, agencias de viaje, transportistas autorizados o agentes de aduanas y/o abogados actuando en nombre o por cuenta de la Compañía.</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Colaborador:</span><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;Cualquier persona vinculada laboralmente con la Compañía a través de un contrato de tiempo determinado o indeterminado.</span></p>
        <p><br /></p>
        <ol start={4} style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'upper-roman', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>ACTIVIDADES PROHIBIDAS</span></p>
          </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Cuando de corrupción se trata, la Compañía no tendrá ningún distingo entre servidores públicos y particulares: El soborno y la corrupción no son tolerados sin importar los involucrados.&nbsp;</span></p>
        <ul style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Ofrecimiento de beneficios indebidos</span><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>.</span></p>
          </li>
        </ul>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Los colaboradores de SCINAPTECH no deben (directamente o a través terceros) ofrecer o realizar sobornos a servidores públicos o particulares (ni a los familiares de éstos ni a terceros) con la intención de inducirlos a usar su posición o poder para ayudar a la Empresa a obtener una ventaja indebida.&nbsp;</span></p>
        <ul style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Recepción de beneficios indebidos</span><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>.&nbsp;</span></p>
          </li>
        </ul>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Los colaboradores no deben solicitar o aceptar (o intentar realizar dichas conductas), ya sea de manera directa o indirecta, a través de algún familiar o tercero, ninguna cosa de valor de un servidor público o un particular por el que, a cambio, pudiera dar como resultado una ventaja aparente e indebida. Queda claro, que para la Compañía no existe cantidad aceptable para un soborno.</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p>
        <ol start={5} style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'upper-roman', fontSize: '10pt', fontFamily: 'Lato,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>REGALOS, COMIDAS, VIAJES Y ENTRETENIMIENTO.</span><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p>
          </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>V.I.- Prohibiciones</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Los colaboradores nunca deben: i) Ofrecer regalos, comidas, viajes o entretenimiento a servidores públicos o a particulares (o sus familiares o terceros) con la intención de influir o inducir al receptor a hacer cualquier cosa que ayude a la Empresa a asegurar o mantener cualquier beneficio o ventaja indebidos. Ii) Pedir, recibir o aceptar regalos, comidas, viajes o entretenimiento a servidores públicos o a particulares (o sus familiares y terceros), sabiendo o suponiendo que, a cambio, dichas personas esperan recibir cualquier beneficio o ventaja indebidos.&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>V.II.- Prácticas aceptables&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Los regalos e invitaciones de negocios nunca deben ser ofrecidos o aceptados con propósitos ilícitos y deben ser, en todos los casos: i) Conformes a las leyes y regulaciones, tanto en el país del que realiza la invitación, como del que la recibe, ii) Entregados o aceptados sin espera de reciprocidad: la intención o propósito que persigue el regalo o invitación debe ser simplemente la de construir la relación de negocio dentro de unos estándares normales de cortesía y no la de influir en la persona encargada de adoptar una determinada decisión de negocio, iii) Aceptables socialmente: otras personas (tales como compañeros, competidores o prensa) deberían estar de acuerdo en que el regalo o invitación es razonable y que se podría comunicar sin temor a un posible reproche social; iv) Consistentes con los intereses de los negocios de la Compañía, así como con las prácticas habituales del negocio, v) Ocasionales y no excesivos, conforme a los estándares locales o de la industria, vi) Registrados en documentación precisa, apropiada y con detalle razonable. Se deberá tener en cuenta que los clientes o proveedores de la Compañía pueden tener sus propias normas internas sobre esta materia y que es posible que no coincidan con los estándares establecidos en esta norma. En consecuencia, se deberá considerar esta circunstancia a la hora de ofrecer regalos o invitaciones a consejeros, directivos y empleados de estas empresas con el fin de evitar que su propia reputación o la de su empresa puedan verse comprometidas.</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>No se considerará irregular la aceptación por parte de los consejeros, directivos y empleados de la Compañía, dentro de los usos sociales empresariales, de invitaciones a eventos promocionales o de negocio, en los que la entidad o la empresa organizadora (distinta de cualquier compañía de SCINAPTECH) asuma los gastos razonables de desplazamiento, alojamiento y/o manutención, siempre que el objeto de dicha invitación sea exclusivamente la presentación de productos o servicios de dicha entidad o empresa organizadora y la invitación no sea individual, sino dirigida a un colectivo de clientes o potenciales clientes.</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>V.III. - Viajes.&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Por ningún motivo un colaborador pagará vacaciones ni tours de ningún tipo a particulares, incluyendo familiares y amigos de éstos; sólo podrá invitar un viaje a un particular, incluyendo transporte aéreo, hospedaje y transportación local, siempre y cuando:&nbsp;</span></p>
        <ul style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Sea para un fin justificado de negocios, como demostrar productos o servicios, capacitaciones y conferencias, o para inspecciones de instalaciones u operaciones de la Empresa.&nbsp;</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Los gastos deben ser conforme a lo necesario y no deben existir gastos irrelevantes o extravagante.&nbsp;</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>En el viaje no deben incluirse amigos ni familiares del invitado;&nbsp;</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>El viaje no implique desviarse o aprovechar la visita a lugares no planeados (estos desvíos son innecesarios cuando requieren extender el viaje original a otros lugares o impliquen realizar gastos innecesarios o injustificables).&nbsp;</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>La invitación al viaje no incluye entregar efectivo como viático para gastar por día.</span></p>
          </li>
        </ul>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>V.IV.- Entretenimiento.&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Un colaborador puede obsequiar boletos para eventos de entretenimiento a un particular, siempre que:&nbsp;</span></p>
        <ul style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>El colaborador esté presente durante el evento;&nbsp;</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>El valor del boleto del evento no exceda a lo irracional o extravagante.&nbsp;</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>El lugar no sea inapropiado, y&nbsp;</span></p>
          </li>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'disc', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>La finalidad del evento sea para promover, demostrar o explicar los productos o servicios de la Empresa.&nbsp;</span></p>
          </li>
        </ul>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Un colaborador puede aceptar boletos para eventos de entretenimiento de un particular, siempre y cuando al evento asista la persona que le hizo la invitación. Cualquier evento de entretenimiento que reciba un colaborador debe ser reportado a la Dirección de la empresa. &nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'underline', WebkitTextDecorationSkip: 'none', textDecorationSkipInk: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>V.V.- Donativos&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>La Empresa no usará donativos como un medio para encubrir actos de corrupción o soborno.&nbsp;</span></p>
        <p><br /></p>
        <ol start={6} style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'upper-roman', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>SOCIOS COMERCIALES&nbsp;</span></p>
          </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>La Compañía dispone de diversas normas que establecen disposiciones obligatorias sobre las relaciones con proveedores y otros Socios Comerciales. La Compañía no contratará o realizará negocios con un Socio Comercial si cree que hay riesgo de que éste vaya a violar las leyes aplicables en materia de anticorrupción o las prohibiciones establecidas en esta Política.&nbsp;</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Antes de establecer una relación de negocios con cualquier Socio Comercial que vaya a interactuar en nombre de la Compañía, con especial énfasis en caso de interactuar con Funcionarios/Empleado Públicos, el área de Compras (en el caso de negociaciones dentro de su ámbito de actuación) o el área de Clientes (en contrataciones fuera del ámbito del área de Compras), deberán realizar una valoración apropiada del Socio Comercial a este respecto y se asegurarán de que: (a) el contrato con el Socio Comercial contiene obligaciones y garantías anticorrupción, así como el derecho de &nbsp;de dar por terminado el contrato en caso de incumplimiento de dichas obligaciones y garantías; (b) el Socio Comercial certifica el cumplimiento de las leyes en materia de anticorrupción.</span></p>
        <p><br /></p>
        <ol start={7} style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'upper-roman', fontSize: '10pt', fontFamily: 'Lato,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>INFORMACIÓN Y NO REPRESALIAS</span><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p>
          </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Todos los colaboradores y/o empleados de SCINAPTECH, así como socios comerciales y proveedores que tengan conocimiento o sospecha de que esta política pueda haber sido violada deberán notificar a la Dirección de Recursos Humanos y/o a la Gerencia Legal de forma inmediata.</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>La empresa no tolerará represalias contra las personas que informen de buena fe. Toda persona que tenga conocimiento de lo que cree ser una forma de represalia debe informar a la Dirección de Recursos Humanos y/o a la Gerencia Legal.</span></p>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p>
        <ol start={8} style={{marginTop: 0, marginBottom: 0, paddingInlineStart: '48px'}}>
          <li aria-level={1} dir="ltr" style={{listStyleType: 'upper-roman', fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-18pt'}}>
            <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>MEDIDAS DISCIPLINARIAS</span></p>
          </li>
        </ol>
        <p dir="ltr" style={{lineHeight: '1.295', marginRight: '-3.9566929133857798pt', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10pt', fontFamily: 'Montserrat,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Los empleados de SCINAPTECH que violen esta política estarán sujetos a medidas disciplinarias, que pueden incluir el despido y también pueden ser objeto de enjuiciamiento penal individual y / o enjuiciamiento civil en las jurisdicciones pertinentes. Así mismo, los socios empresariales y/o colaboradores que violen esta política están sujetos a la terminación de todas las relaciones comerciales con SCINAPTECH, sin perjuicio de las acciones penales o civiles que puedan ejercerse.&nbsp;</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PoliticaAnticorrupcion;