import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Servicio al público',
        description: 'Al proporcionar bienes y servicios de alta calidad a precios razonables, contribuimos al bienestar del público.'
    },
    {
        icon: <FiCast />,
        title: 'Equidad y honestidad',
        description: 'Seremos justos y honestos en todos nuestros tratos comerciales y conducta personal.'
    },
    {
        icon: <FiMap />,
        title: 'Trabajo en equipo por la causa común',
        description: 'Pondremos en común nuestras habilidades, basándonos en la confianza y el respeto mutuos.'
    },
    {
        icon: <FiMap />,
        title: 'Uniendo esfuerzos para la mejora',
        description: 'Nos esforzaremos constantemente por mejorar nuestro desempeño corporativo y personal.'
    },
    {
        icon: <FiMap />,
        title: 'Cortesía y humildad',
        description: 'Siempre seremos cordiales y modestos y respetaremos los derechos y necesidades de los demás.'
    },
    {
        icon: <FiMap />,
        title: 'De acuerdo con las leyes naturales.',
        description: 'Cumpliremos con las leyes de la naturaleza y nos ajustaremos a las condiciones cambiantes que nos rodean.'
    },
    {
        icon: <FiMap />,
        title: 'Gratitud por la bendición',
        description: 'Siempre estaremos agradecidos por todas las bendiciones y bondades que hemos recibido.'
    }
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            {/* <div className="icon">
                                {val.icon}
                            </div> */}
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;