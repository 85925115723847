import {useState, useRef} from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";


const HeaderOne = ({btnStyle, HeaderSTyle}) => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader( 50 );
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`
    const { clientHeight } = ref;
    
    const checkChange = (value) => {
      setCheck(value);
    };

    return (
        <>
            <header ref={ref} className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}>
                <div className="container position-relative">
                    <div className="row align-items-center row--0">
                        <div className="col-lg-3 col-md-6 col-4">
                            <Logo 
                                image={`https://res.cloudinary.com/duooqqgsu/image/upload/v1637678509/scinaptech/LOGOTYPE_SCINAPTECH_npr5aa.svg`}
                                image2={`https://res.cloudinary.com/duooqqgsu/image/upload/v1637678509/scinaptech/LOGOTYPE_SCINAPTECH_npr5aa.svg`}
                            />
                        </div>
                        <div className="col-lg-9 col-md-6 col-8 position-static">
                            <div className="header-right">
                                <nav className="mainmenu-nav d-none d-lg-block">
                                    <Nav />
                                </nav>
                                {/* <div className="header-btn">
                                    <a className={`btn-default ${btnStyle}`} target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">BooUY NOW</a>
                                </div> */}
                                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div className="hamberger">
                                        <button className="hamberger-button" onClick={onCanvasHandler}>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="20" height="20" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M1215 3666 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 26
-8 449 -10 1354 -8 1312 3 1317 3 1344 24 53 39 69 71 69 134 0 63 -16 95 -69
134 -27 21 -31 21 -1354 23 -1088 2 -1332 0 -1357 -11z"/>
<path d="M1215 2706 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 26
-8 449 -10 1354 -8 1312 3 1317 3 1344 24 53 39 69 71 69 134 0 63 -16 95 -69
134 -27 21 -31 21 -1354 23 -1088 2 -1332 0 -1357 -11z"/>
<path d="M1215 1746 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 26
-8 449 -10 1354 -8 1312 3 1317 3 1344 24 53 39 69 71 69 134 0 63 -16 95 -69
134 -27 21 -31 21 -1354 23 -1088 2 -1332 0 -1357 -11z"/>
</g>
</svg>

                                        </button>
                                    </div>
                                </div>
                                {/* <Darkmode /> */}
                            </div>  
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler}  />
        </>
    )
}
export default HeaderOne;